import Footer from './Footer';

export default function Privacy() {
    
    return <div className='body'>
        <div id="main" className="info">
            <h1>Politique de confidentialité</h1>
            <div className="content">
                <p>Notre politique de confidentialité décrit comment l'application des 3 sources traite l'ensemble des informations saisies ainsi que vos droits.</p>
                
                <h2>1. Données collectées et traitement</h2>
                <p>
                    L'application collecte uniquement les données saisies au sein de l'application (cavaliers, chevaux, cours, règlements...) afin de les sauvegarder en base de données.
                    Aucune autre donnée ne saurait être collectée à votre insu.
                    Vos données sont confidentielles, aucun traitement n'est effectué en dehors du cadre de l'application, aucune utilisation ou revente d'information ne serait faite à une tierce partie.
                </p>
                
                <h2>2. Sécurité des données</h2>
                <p>
                    L'application enregistre des données personnelles sensibles comme celles de vos cavaliers. A ce titre la sécurisation de toute la chaine de traitement est notre priorité.
                    Nous utilisons une base de donnée sécurisée pour garantir un stockage fiable, avec chiffrement des données au repos, hébergé en France.
                    Le système d'authentification et d'accès de l'application est pensé pour être robuste face à d'éventuelles attaques. Le protocole https et l'utilisation de jetons uniques garantit
                    le transport sûr et chiffré des données de bout en bout.
                    Un système de sauvegardes automatiques est mis en place pour éviter une perte de données.
                </p>
            
                <h2>3. Vos droits</h2>
                <p>
                    Votre espace web vous permet de récupérer à tout moment l'ensemble de vos données.
                    En cas de demande de clotûre de votre compte, nous nous engageons à vous fournir une sauvegarde de celles-ci et de détruire toutes les informations de notre base de données.
                </p>
            </div>
            
        </div>    
        <Footer />
    </div>
}