import '../style/admin.css';
import { Component } from "react";
import Login from './Login'
import Footer from './Footer';
import Profile from './Profile';
import { getEndpoint, myFetch } from '../helpers/Api';
import { Navigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { EventRegister } from 'react-native-event-listeners';

export default class Admin extends Component{
  
    static entity = 'admin';

    constructor(props) {
        super(props);
        this.state = {
            isLogged: window.localStorage.getItem('adminLogged'),
            emailType: 'all',
            licenceSearch: null,
            showLessonsHistory: false
        };
    }

    componentDidMount() {
        this.listeners = [EventRegister.addEventListener('logout', this.logout)];
    }

    componentWillUnmount() {
        // Clear event listeners
        this.listeners.forEach(x => EventRegister.removeEventListener(x));
    }

    getEmails = () => {
        myFetch('get_emails?type=' + this.state.emailType).then(response => response.json())
        .then(emails => {
            navigator.clipboard.writeText(emails);
            toast(emails.length + " emails copiés");
        });
    }

    onLessonHistorySearch = (event) => {
        event.preventDefault();

        this.setState({showLessonsHistory: true})
    }

    onEmailTypeChange = (event) => {
        this.setState({emailType: event.target.value})
    }

    onLicenceSearchChange = (event) => {
        this.setState({licenceSearch: event.target.value})
    }

    onLoginSuccess = () => {
        window.localStorage.setItem('adminLogged', true);
        this.setState({isLogged: true})
    }

    logout = () => {
        if (this.state.isLogged) {
            myFetch('logout_admin').catch(() => {console.log('logout failed')});
            window.localStorage.removeItem('adminLogged');
            this.setState({isLogged: false})
        }
    }

    renderAdmin = () => {
        return <>
            {this.state.showLessonsHistory && <Navigate to="/cours" relative="" state={{licenceNum: this.state.licenceSearch}} />}

            <Profile entity={Admin.entity} onLogout={this.logout} />
            
            <h1>Bonjour admin !</h1>
            <h2><a href={getEndpoint('get_riding_plan')}>Feuille de monte du jour</a></h2>

            <h2>Copie d'emails</h2>
            <div className="email">
                <select name="emailtype" onChange={this.onEmailTypeChange} value={this.state.emailType}>
                    <option value="all">Tous les cavaliers</option>
                    <option value="pension">Propriétaires avec pension</option>
                    <option value="debtors">Débiteurs</option>
                </select>
                <input id="emailbtn" className="button" type="button" value="Copier les emails" onClick={this.getEmails} />
            </div>
            <h2>Historique de Cours</h2>
            <form id='lessonHistorySearch' onSubmit={this.onLessonHistorySearch}>
                <input style={{width: '150px'}} type="text" value={this.state.licenceSearch}
                placeholder="N° Licence" onChange={this.onLicenceSearchChange}/>
                <input className='submit' type='submit' value="Chercher" />
            </form>
        </>
    }

    render() {
        return <div className='body'>
        <div id='main'>
            {/* Logged ? Display rider info */}
            {this.state.isLogged && this.renderAdmin() }

            {/* Admin form */}
            {!this.state.isLogged && <>
            <Login entity={Admin.entity} loginLabel='Login' onLoginSuccess={this.onLoginSuccess}/>
            </>}
        </div>
       
        <Footer />

        </div>
    }
}

