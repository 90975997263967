
export default function Modal(props) {
    let down_elt = null;

    const onMouseDown = (event) => {
        down_elt = event.target;
    }

    const onMouseUp = (event) => {
        const target = event.target;
        
        if (target.className === 'frame') {
            event.stopPropagation();
        }
        else if (target.className.includes('modal') && down_elt.className.includes('modal')) {
            props.onClose()
        }
    }

    return <div className={'modal ' + (props.modalClass || '')} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
        <div className={'frame ' + (props.frameClass || '')}>
        {props.children}
        </div>
    </div>
}