import '../style/rider.css';
import { Component } from 'react';
import Login from './Login'
import Footer from './Footer';
import { myFetch } from '../helpers/Api';
import Profile from './Profile';
import { EventRegister } from 'react-native-event-listeners';

export default class Rider extends Component {

    static entity = 'rider';

    constructor(props) {
		super(props);
		this.state = {
            isLogged: window.localStorage.getItem('riderLogged'),
            rider: null
        }
    }

    componentDidMount() {
        this.listeners = [EventRegister.addEventListener('logout', this.logout)];

        if (this.state.isLogged) {
            myFetch('get_rider_web').then(res => res.json()).then(rider => {
                this.setState({rider})
            }).catch(this.logout)
        }
    }

    componentWillUnmount() {
        // Clear event listeners
        this.listeners.forEach(x => EventRegister.removeEventListener(x));
    }

    onLoginSuccess = (rider) => {
        window.localStorage.setItem('riderLogged', true);
        this.setState({rider: rider, isLogged: true})
    }

    logout = () => {
        if (this.state.isLogged || this.state.rider) {
            myFetch('logout_rider').catch(() => {console.log('logout failed')});
            window.localStorage.removeItem('riderLogged');
            this.setState({rider: null, isLogged: false})
        }
    }

    amountText = (amount) => {
        const prettyAmount = amount % 1 === 0 ? Math.round(amount) : amount.replace('.', ',');
        return prettyAmount + '€';
    }

    dateToShortText = (date) => {
        const twoDigits = (num) => {
            if (num <= 9)
                return '0' + num;
        
            return num;
        }
        
        return [twoDigits(date.getDate()), twoDigits(date.getMonth() + 1), date.getFullYear()].join('/');
    }

    getCreditsClass = (nbCredits) => {
        let type = '';
        if (nbCredits > 0)
            type = 'creditor';
        else if (nbCredits < 0)
            type = 'debtor';

        return 'creditsCount ' + type;
    }

    renderRider = () => {
        const rider = this.state.rider;

        return <>
            <Profile entity={Rider.entity} onLogout={this.logout} />
            
            <h1>Bonjour {rider.firstName} !</h1>
            <h2 style={{textAlign: 'center'}}>Voici vos crédits pour les reprises d'équitation à la carte:</h2>
            <ul>
                <li>Cours collectifs:
                    <span className={this.getCreditsClass(rider.creditGroupLesson)} >
                    {' ' + rider.creditGroupLesson}</span>
                </li>
                <li>Cours particuliers:
                    <span className={this.getCreditsClass(rider.creditPrivateLesson)}>
                    {' ' + rider.creditPrivateLesson}</span>
                </li>
            </ul>

            {rider.hasDebts &&
            <div id="debts">
                <h2>Prestations impayées:</h2>
                <ul id="debtsUL">
                    {rider.debts.map(x => <li className='debt' key={x.id}>
                        <ul>
                            <li>{x.name}</li>
                            {x.description.trim() && <li>{x.description}</li>}
                            <li>{this.amountText(x.amount)}</li>
                            <li>{this.dateToShortText(new Date(x.serviceDate))}</li>
                        </ul>
                    </li>)}
                </ul>
            </div>}

            <div id="lessonsHistoryLink">
                <h2><a href="cours">Historique des cours</a></h2>
            </div>
        </>
    }

    render() {
        return <div className='body'>
        <div id='main'>
            {/* Logged ? Display rider info */}
            {this.state.rider && this.renderRider() }

            {/* Login form */}
            {!this.state.isLogged && <>
            <h1>Bienvenue sur votre espace cavalier !</h1>
            <Login entity={Rider.entity} loginLabel='N° Licence:' onLoginSuccess={this.onLoginSuccess}/>
            </>}
        </div>
       
        <Footer />

        </div>
    }
}