import Footer from './Footer';

export default function AccountDelete() {
    
    return <div className="body">
        <div id="main" className="info">
            <h1>Suppression de compte</h1>
            <div className="content">
            <p>
                Pour supprimer votre compte des 3 Sources, envoyez un email à adm3sources@gmail.com<br />
                Nous nous engageons à supprimer l'intégralité de vos données après confirmation avec vous.
            </p>
        </div>
    </div>
    <Footer />
</div>
}