import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navigate } from "react-router-dom";
import Rider from './components/Rider';
import Admin from './components/Admin';
import Privacy from './components/Privacy';
import LessonsHistory from './components/LessonsHistory';
import AccountDelete from './components/AccountDelete';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {

  return <Router>
        <Routes>
            <Route path="/" element={<Navigate to='/cavalier' />} />
            <Route path="/cavalier" element={<Rider />} />
            <Route path="/cours" element={<LessonsHistory />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/account_delete" element={<AccountDelete />} />
        </Routes>

        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            theme="light"
            toastClassName={'mytoast'} 
        />
    </Router>
}

export default App;
