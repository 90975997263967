import { Component } from 'react';
import Modal from './Modal';
import { myFetch } from '../helpers/Api';
import { toast } from 'react-toastify';

export default class ForgottenPassword extends Component {

    static Steps = {
        Email: 0,
        Code: 1,
        NewPassword: 2
    }

    constructor(props) {
		super(props);
		this.state = {
            step: ForgottenPassword.Steps.Email,
            email: '',
            code: '',
            newPassword: '',
            newPasswordConfirm: '',
            formError: false,
            newPasswordValid: false
        }
    }

    onSubmit = (event) => {
        // Do not reload page
        event.preventDefault();

        switch (this.state.step) {
            case ForgottenPassword.Steps.Email:
                this.getResetPasswordCode();
                break;
            case ForgottenPassword.Steps.Code:
                this.checkCode();
                break;
            default:
                this.setNewPassword();
        }
    }

    nextStep = () => {
        this.setState({step: this.state.step + 1, formError: false});
    }

    getResetPasswordCode = () => {
        myFetch('get_reset_password_code', {email: this.state.email}).then(
            // Success: next step !
            this.nextStep, 
            // Error
            res => { if (res.status === 404) this.setState({formError: true }) }
            )
            .catch(e => {});
    }

    checkCode = () => {
        myFetch('reset_password_check_code', {code: this.state.code}).then(
            // Success
            this.nextStep, 
            // Error
            res => {
                if (res.status === 403)
                    this.setState({formError: true})
                else if (res.status === 401)
                    this.props.onClose()
            }
            )
            .catch(e => {});
    }

    setNewPassword = () => {
        if (!this.state.newPassword || !this.state.newPasswordConfirm || this.state.newPassword.length < 8)
        {
            this.setState({formError: 'Mot de passe invalide'});
            return;
        }

        if (this.state.newPassword !== this.state.newPasswordConfirm) {
            this.setState({formError: 'Les deux entrées ne sont pas identiques !'});
            return;
        }

        myFetch('set_new_password', {newPassword: this.state.newPassword, newPasswordConfirm: this.state.newPasswordConfirm}).then(() => {
            this.props.onClose();
            toast('Mot de passe bien changé')
        })
        .catch(e => this.setState({formError: 'Le mot de passe n\'a pu être changé'}));
    }

    render() {
        return <form onSubmit={this.onSubmit}><Modal onClose={this.props.onClose}>
                <div className="reset_password">
                    {this.state.step === ForgottenPassword.Steps.Email && <div className='email_reset'>
                        <h3>Votre email:</h3>
                            <div className="flexrow">
                                <input className="email" type='text' value={this.state.value} onChange={(e) => this.setState({email: e.target.value})}/>
                                <input className='submit' type='submit' value="Suivant" />
                            </div>
                            {this.state.formError &&
                            <p className="error">Pas de cavalier associé à cet email. Contactez votre centre !</p>}
                        </div>
                    }
                    {this.state.step === ForgottenPassword.Steps.Code && <div className='enter_code'>
                        <h3>Saisissez le code à 6 chiffres</h3>
                        <p>Entrez le code reçu sur votre email</p>
                        <div className="flexrow">
                            <input type='text' value={this.state.code} onChange={(e) => this.setState({code: e.target.value})} />
                            <input className='submit' type='submit' value="Envoyer" />
                        </div>
                       {this.state.formError && <p className="error">Mauvais code. Une licorne explosive interviendra au troisième essai</p>}
                       </div> 
                    }
                    {this.state.step === ForgottenPassword.Steps.NewPassword && <div className="new_password">
                        <h3>Nouveau mot de passe</h3>
                        <div className="rules">(au moins 8 caractères)</div>
                        <input type='password' value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})}/><br />
                        <p>Confirmer le mot de passe:</p>
                        <input type='password' value={this.state.newPasswordConfirm} onChange={(e) => this.setState({newPasswordConfirm: e.target.value})} /><br />
                        {this.state.formError && <p className="error">{this.state.formError}</p>}
                        <div className='submit_wrapper'>
                            <input className='submit' type='submit' value="Valider"/>
                        </div>
                    </div>}
                </div>
            </Modal></form>
    }
}