
import {useState} from 'react';
import profileIcon from '../images/rider.png';
import ChangePassword from './ChangePassword';
import { toast } from 'react-toastify';

export default function Profile(props) {
    let [menuVisible, showMenu] = useState(false);
    let [showChangePassword, setShowChangePassword] = useState(false)

    const openChangePassword = () => {
        setShowChangePassword(true);
        showMenu(false)
    }

    const onCloseChangePassword = () => {
        setShowChangePassword(false);
    }

    const onPasswordChanged = () => {
        onCloseChangePassword();
        toast("Mot de passe bien changé");
    }

    return <>
        <div id="profile" onClick={() => showMenu(true)}>
            <img src={profileIcon} alt='profile' width="150" />
        </div>

        {menuVisible && <div id="profileMenu" className="modal transparent" onMouseDown={(event) => event.preventDefault()} onClick={() => showMenu(false)}>
            <ul className="frame" onMouseDown={(event) => event.preventDefault()} onClick={(event) => { event.stopPropagation(); event.preventDefault() }}>
                <li><button onClick={props.onLogout}>Déconnexion</button></li>
                <li><button onClick={openChangePassword}>Mot de passe</button></li>
            </ul>
        </div>}

        {showChangePassword && <ChangePassword entity={props.entity} onClose={onCloseChangePassword} onChange={onPasswordChanged} />}
    </>
}