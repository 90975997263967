import '../style/rider.css';
import { myFetch } from '../helpers/Api';
import { useState } from 'react';
import ForgottenPassword from './ForgottenPassword';

export default function Login(props) {

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [showError, setShowError] = useState(false)

    const onSubmitLogin = (event) => {
        // Do not reload page
        event.preventDefault();

        myFetch(props.entity + '_login_web', {
            'login': login,
            'password': password
        }).then(res => res.json()).then(data => {
            props.onLoginSuccess(data);
        }).catch(() => {
            setShowError(true)
        })
    }

    const togglePasswordModal = () => {
        setShowModal(!showModal);
    }

    return <div>
            <form name="login" onSubmit={onSubmitLogin}>
                <h2>{props.loginLabel}</h2>
                <input type='text' name='user' value={login} onChange={(e) => setLogin(e.target.value)} />
                <h2>Mot de passe:</h2>
                <input type='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} /><br />
                {props.entity === 'rider' &&
                    <div id="forgottenPasswordLink"><span onClick={togglePasswordModal}>Mot de passe oublié ?</span></div>
                }<br />
                {showError && <div className="error" style={{marginBottom: "10px" }}>{props.entity === 'rider' ? 'Licence' : 'Login'} ou mot de passe incorrect</div>}
                <input className='submit' type='submit' value="Connexion" />
            </form>

            {/* Forgotten password modal */ }
            {showModal && <ForgottenPassword onClose={togglePasswordModal} />}
    </div>
}

