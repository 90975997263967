import { EventRegister } from 'react-native-event-listeners';
const IS_DEV = false;

const getEndpoint = function(route) {
    let host = IS_DEV ? ('http://localhost:5000/') : '/';
    return host + route;
}

const buildRequest = function(body) {
    return {
        method: body ? 'POST': 'GET',
        headers: {
             'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: body ? JSON.stringify(body) : null,
        cache: 'no-store'
    };
}

const myFetch = (route, body) => {
    return new Promise((resolve, reject) => {
        fetch(getEndpoint(route), buildRequest(body), ).then(res => {
            if (res.ok) {
                resolve(res);
                return;
            }

            if (res.status === 401)
                EventRegister.emit('logout')

            reject(res);
    
        }).catch(res => reject(res));
    });
}

export {getEndpoint, myFetch};