import {useState} from 'react';
import { myFetch } from '../helpers/Api';
import Modal from './Modal';

export default function ChangePassword(props) {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const [errorText, setErrorText] = useState(false)
    const changePasswordRoute = props.entity === 'rider' ? 'rider_change_password' : 'admin_change_password';

    const onSubmit = (event) => {
        // Do not reload page
        event.preventDefault();

        if (!newPassword || !newPasswordConfirm || newPassword.length < 8)
        {
            setErrorText('Mot de passe invalide');
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            setErrorText('Les deux entrées ne sont pas identiques !');
            return;
        }

        myFetch(changePasswordRoute, {currentPassword, newPassword, newPasswordConfirm}).then(() => {
            props.onChange();
        })
        .catch(e => setErrorText('Le mot de passe n\'a pu être modifié'));
    }

    return <form className="change_password" onSubmit={onSubmit}><Modal onClose={props.onClose}>
            <h3>Changement du mot de passe</h3>
            <h4>Mot de passe actuel</h4>
            <input type='password' value={currentPassword} onChange={(event) => setCurrentPassword(event.target.value)} /><br />
            <h4 className="new">Nouveau:</h4>
            <div className="rules">(au moins 8 caractères)</div><br />
            <input type='password' value={newPassword} onChange={(event) => setNewPassword(event.target.value)}/>
            <p>Confirmer:</p>
            <input type='password' value={newPasswordConfirm} onChange={(event) => setNewPasswordConfirm(event.target.value)}/><br />
            {errorText && <div className="error">{errorText}</div>}
            <br />
            <div className='submit_wrapper'>
                <input className='submit' type='submit' value="Valider" />
            </div>
    </Modal></form>
}